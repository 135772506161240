import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  myForm!: FormGroup;
  isLoader: boolean = false;
  fieldTextType: boolean | undefined;
  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private route: Router, private CommonService: CommonService) { }

  ngOnInit(): void {
    this.myForm = this.formBuilder.group({
      password: ['', [Validators.required, this.passwordValidator()]],
      confirmPassword: ['', Validators.required]
    });
  }
  checkPassword() {
    // const passwordControl = this.myForm.get('password');
    // if (passwordControl.dirty && passwordControl.invalid) {
    //   if (passwordControl.errors.required) {
    //     this.toastr.error("Password is required");
    //   } else if (passwordControl.errors.pattern) {
    //     this.toastr.error("Password must have at least 8 characters including one uppercase letter, one lowercase letter, one number, and one special character");
    //   }
    // } else {
    //   this.toastr.success('Password is valid');
    // }
    var myInput: HTMLInputElement = document.getElementById("password") ? document.getElementById("password") : {} as any;
    var letter: any = document.getElementById("letter");
    var capital: any = document.getElementById("capital");
    var number: any = document.getElementById("number");
    var length: any = document.getElementById("length");
    var special: any = document.getElementById('specialCharacter');


    var Character = /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/;
    if (myInput.value.match(Character)) {
      special.classList.remove("invalid");
      special.classList.add("valid");
    } else {
      special.classList.remove("valid");
      special.classList.add("invalid");
    }

    var lowerCaseLetters = /[a-z]/g;
    if (myInput.value.match(lowerCaseLetters)) {
      letter.classList.remove("invalid");
      letter.classList.add("valid");
    } else {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (myInput.value.match(upperCaseLetters)) {
      capital.classList.remove("invalid");
      capital.classList.add("valid");
    } else {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (myInput.value.match(numbers)) {
      number.classList.remove("invalid");
      number.classList.add("valid");
    } else {
      number.classList.remove("valid");
      number.classList.add("invalid");
    }

    // Validate length
    if (myInput.value.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");
    } else {
      length.classList.remove("valid");
      length.classList.add("invalid");
    }


  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  passwordValidator() {
    return Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
  }
  submit(form: FormGroup) {
    this.isLoader = true;
    let value = form.value;
    let payload = {
      "userid": localStorage.getItem('UserId'),
      "password": value.password
    }
    var password: any = (<HTMLInputElement>document.getElementById("password"))?.value;
    var confirmPassword: any = (<HTMLInputElement>document.getElementById("confirmPassword"))?.value;
    if (password != confirmPassword) {
      this.toastr.warning("Passwords do not match.");
      return this.isLoader = false;
    } else {
      if (value.password == "") {
        this.toastr.error("Password is required");
        return this.isLoader = false;
      }
      this.CommonService.postCall(`Registration/UpdateUserPassWord`, payload).subscribe((res: any) => {
        this.toastr.success("Password Changed Successfully");
        this.isLoader = false;
        this.Signout();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Password Not Changed');
        this.isLoader = false;
      })
    }
  }

  Signout() {
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.route.navigate(['/login']);
    }, 500);
  }
}
