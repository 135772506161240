import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ChatService } from 'src/app/services/chat.service';
import { error } from 'console';

declare var $: any;
declare const register: any;
declare const call: any;
declare const statusRegister: any
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  DEFAULT_PWD = 'ABC123';
  data: any = {};
  emailId: string = '';
  loginForm: FormGroup;
  apires: any;
  submitted = false;
  fieldTextType: boolean | undefined;

  WebSocket_URL = environment.WebSocket_URL;
  OpenVidu_URL = environment.OpenVidu_URL;

  constructor(private userService: LoginService, private chatService: ChatService, private route: Router, private toastr: ToastrService, private commonService: CommonService,
    private fb: FormBuilder) {
    // this.getCompanyDetails();

  }

  ngOnInit(): void {
    const bo: any = document.body;
    bo.style = '';
    // this.init()
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
    // ["userName", "password"].forEach(e => {
    //   const element = (document.getElementById('userName') as HTMLInputElement);
    //   element.setAttribute("autocapitalize", "off");
    //   element.setAttribute("autocomplete", "off");
    //   element.setAttribute("autocorrect", "off");
    //   element.setAttribute("spellcheck", "off");
    // });
    setTimeout(() => this.companyDetails(), 10);
  }

  get f() { return this.loginForm.controls; }

  registerUser(form: FormGroup) {
    this.submitted = true;
    let user = this.loginForm.getRawValue();
    if (user.userName.trim().length == 0) {
      //this.toastr.error("Please enter userName");
      (document.getElementById('userName') as HTMLInputElement).focus();
    }
    if (user.password.trim().length == 0) {
      //this.toastr.error("Please enter password");
      (document.getElementById('password') as HTMLInputElement).focus();
    }

    if (this.loginForm.invalid) {
      this.toastr.error("Please enter username or password");
      return;
    }
    this.commonService.activateSpinner();
    // user.company_id = sessionStorage.company_id || 0;
    this.userService.login(user).subscribe((succ) => {
      let userData: any = succ ?? {};
      // if(userData?.USERID == null){
      //   this.route.navigate(['login']);
      // }
      this.commonService.deactivateSpinner();
      this.commonService.userId = userData.USERID
      sessionStorage.setItem('UserId', userData.USERID);
      sessionStorage.setItem('Username', userData.FIRSTNAME);
      sessionStorage.setItem('TenantCode', userData.TENANTCODE)
      sessionStorage.setItem('RoleId', userData.ROLEID)
      // sessionStorage.setItem('DICTIONARYCODE', userData.DICTIONARYCODE)
      // sessionStorage.setItem('SU', userData.samvaad_user);
      // sessionStorage.setItem('SP', userData.samvaad_password);
      // sessionStorage.setItem('Proctoring', userData.allow_proctoring);
      // localStorage.setItem('Logo', userData.TNT_LOGO);
      localStorage.setItem('RoleName', userData.ROLE_NAME);
      localStorage.setItem('tkn', userData?.token);
      // let time: any = new Date();
      // localStorage.setItem('loginTime', time);
      // sessionStorage.setItem('USERTYPE', userData.USERTYPE);
      // sessionStorage.MobileNo = userData.MobileNo;
      sessionStorage.USERNAME = userData.USERNAME;
      // sessionStorage.PASSWORD = userData.PASSWORD;
      // sessionStorage.company_id = userData.company_id;
      // sessionStorage.is_company = userData.is_company;
      sessionStorage.FullName = `${userData.FIRSTNAME} ${userData.LASTNAME}`.toUpperCase();
      // sessionStorage.setItem('exampad',userData.allow_exampad);
      sessionStorage.profileImage = (userData.USERIMAGE || 'data:,').replace('data:,', "https://i.pinimg.com/736x/89/90/48/899048ab0cc455154006fdb9676964b3.jpg");
      sessionStorage.userData = JSON.stringify(userData);
      const title = (+userData.ROLEID == 3) ? "" : "Prof. ";
      let usrNme: string = title + `${userData.FIRSTNAME} ${userData.LASTNAME}`.toUpperCase();
      usrNme = usrNme.length > 25 ? usrNme.substring(0, 23) + '..' : usrNme;
      sessionStorage.usrNme = usrNme;
      sessionStorage.isLogIn = true;
      (<HTMLInputElement>document.getElementById('user')).value = usrNme;

      localStorage.setItem('UserId', userData.USERID);
      localStorage.setItem('Username', userData.FIRSTNAME);
      localStorage.setItem('TenantCode', userData.TENANTCODE);
      localStorage.setItem('RoleId', userData.ROLEID);
      localStorage.setItem('courseId', userData.CourseId);
      localStorage.setItem('countryID', userData?.country_id);
      // localStorage.setItem('DICTIONARYCODE', userData.DICTIONARYCODE)
      // localStorage.setItem('SU', userData.samvaad_user);
      // localStorage.setItem('SP', userData.samvaad_password);
      localStorage.setItem('Proctoring', userData.allow_proctoring);
      // localStorage.setItem('Logo', userData.TNT_LOGO);
      localStorage.setItem('RoleName', userData.ROLE_NAME);
      localStorage.setItem('CourseId', userData.CourseId);
      // (localStorage.setItem('aprvId', userData.is_approved) as any)
      // localStorage.setItem('IsPaid', userData.appl_paydet_ispaid)
      // localStorage.setItem('exampad',userData.allow_exampad);     
      // this.commonService.userImage.next(sessionStorage.profileImage);

      const { TenantCode = 0, UserId = 0, RoleId = 0, Username = "" } = sessionStorage;
      if (+RoleId == 2) {
        this.commonService.postCall("Courses/LoadCoursesByTrainer", { USER_ID: UserId, TNT_CODE: TenantCode }).subscribe(rep => {
          if (rep) {
            let courseList: Array<any> = rep.map(e => ({ courseId: e.COURSE_ID, courseName: e.COURSE_NAME, teacherId: UserId }));
            const uniqueIds = [];
            courseList = courseList.filter(e => {
              const isNotExist: boolean = !uniqueIds.includes(e.courseId);
              if (isNotExist)
                uniqueIds.push(e.courseId);
              return isNotExist;
            });
            (<any>window).courseInfo = courseList;
            (<any>window).is_login = true;
            sessionStorage.courseList = JSON.stringify(courseList);
            setTimeout(() => {
              this.openVeduURLs();
              this.webRTC_method(true, userData);
            }, 100);
          }
        });
      }

      /*
      2 Teacher
      3 Student
      */
      if ([2, 3].includes(+RoleId)) {
        const userType: string = (RoleId == 2) ? "Teacher" : "Student";
        const chatobject = { TenantCode, UserId, userType, Username };
        sessionStorage.ChatUser = JSON.stringify(chatobject);
        this.chatService.connectSocket(chatobject.TenantCode, chatobject.UserId, chatobject.userType, chatobject.Username);
      }
      userData.is_firstlogin == true ? this.route.navigate(['/HOME']) : this.route.navigate(['/changePassword']);
      this.roleChange(atob(userData.ROLEID),userData.TENANTCODE)
    }, (err) => {
      let e = err.error;
      this.commonService.deactivateSpinner();
      this.toastr.error(e?.text || 'Please try again later')
    
    })
  }

  roleChange(rId: any,tntCode:any) {
    this.commonService.activateSpinner();
    this.commonService.getCall(`PermissionRole/GetListById/${tntCode}/${rId}`).subscribe((res: any) => {
      localStorage.setItem('isApprove', btoa(JSON.stringify(res.data)));
      this.commonService.deactivateSpinner();
    });
    this.commonService.deactivateSpinner();
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType
  }
  navigateToOuk() {
    window.open('https://ouk.ac.ke/')
  }
  validateEmail(event: any) {
    let val = event.target.value;
    let exp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;


    if (new RegExp(exp).test(val)) {
      // Check if there are numbers after "@" symbol
      let parts = val.split('@');
      if (parts.length === 2 && /\d/.test(parts[1])) {
        this.emailId = null;
        this.toastr.warning('Numbers are not allowed after "@"');
        // this.commonService.deactivateSpinner();
      } else {
        this.emailId = val;
      }
    } else {
      this.emailId = null;
      this.toastr.warning('Invalid email credentials, please enter a valid email');
      // this.commonService.deactivateSpinner();
    }
    // this.commonService.deactivateSpinner();


  }
  close() {
    this.emailId = '';
  }
  activeSpinner() {
    this.commonService.activateSpinner();
  }
  deactiveSpinner() {
    this.commonService.deactivateSpinner();
  }
  onForgotPasswordClick() {
    // this.route.navigate(['/forgotPassword']);
    this.activeSpinner();
    if (this.emailId == '') {
      this.toastr.warning("Please enter the email id");
      this.deactiveSpinner();
      return
    }
    this.commonService.getCall(`Account/ForgotPassword/${this.emailId?.trim()}`).subscribe((res: any) => {
      this.apires = res;
      this.toastr.success(this.apires?.message);
      document.getElementById('md_close')?.click();
      this.emailId = '';
      this.deactiveSpinner();
    }, (err: any) => {
      this.toastr.error(err.error ? err.error?.message : err.error?.message);
      this.deactiveSpinner();
    })
    this.deactiveSpinner();

    // let user = this.loginForm.getRawValue();
    // if (user.userName.trim().length > 0) {
    //   const { company_id = 0 } = sessionStorage;
    //   const payload: string = `Account/ForgotPassword/${user.userName.trim()}`;

    //   this.userService.forgotPassword(payload).subscribe((res: any) => {
    //     const message: string = "Your Password has been sent to your registered email.";

    //     if (res.message == message) {
    //       this.toastr.success(message, "Login Page");
    //     }
    //     else {
    //       this.toastr.warning(res.message, "Login Page");
    //     }
    //   }, (err) => {
    //     let e = err.error;
    //     this.toastr.error(e?.text || 'Please Enter Valid Registered Email ID');

    //   });
    // }
    // else {
    //   this.toastr.error("Please enter valid email", "Login Page");
    //   (document.getElementById("userName") as HTMLInputElement).focus();
    //   this.commonService.deactivateSpinner();
    // }
    // this.commonService.deactivateSpinner();
  }

  companyDetails() {
    const { fileUrl } = environment;
    if (sessionStorage.homepageimage_path) {
      document.getElementById("homepageimage_path").setAttribute("src", `${fileUrl}${sessionStorage.homepageimage_path} `);

    }
  }

  gotoSignUp() {
    // const URL = (/true/).test(sessionStorage.isDomain) ? "/student-signup" : "/signup";
    this.route.navigate(['student-signup']);

    // this.route.navigate([URL]);
  }

  openVeduURLs() {
    (<HTMLInputElement>document.getElementById('ovUrl')).value = this.OpenVidu_URL;
    (<HTMLInputElement>document.getElementById('ovKey')).value = "dipl123";
    (<HTMLInputElement>document.getElementById('apUrl')).value = this.WebSocket_URL;

    (<any>window).OPENVIDU_SERVER_URL = this.OpenVidu_URL;
    (<any>window).OPENVIDU_SERVER_SECRET = "dipl123";
    (<any>window).vidmed_url = `${this.WebSocket_URL}telecon/`;

    sessionStorage.OpenVidu_URL = this.OpenVidu_URL;
    sessionStorage.WebSocket_URL = this.WebSocket_URL;

  }

  webRTC_method(isTeacher: boolean = true, userData: any) {
    if (!userData)
      return;
    const userInfo = {
      toId: '',
      fromId: `tea_${userData.USERID}`,
      name: userData.FIRSTNAME,
      userType: 1,
      complaints: '',
      myId: userData.USERID,
      usrTkn: ''
    };

    // if (isTeacher)
    //   [userInfo.toId, userInfo.fromId, userInfo.name, userInfo.userType, userInfo.complaints, userInfo.myId] = ['', `tea_${userData.USERID}`, userData.FIRSTNAME, 1, '', userData.USERID];

    userInfo.usrTkn = `org_${userData.TENANTCODE}_${userData.TNT_NAME}_${userInfo.fromId}_${userData.FIRSTNAME}_${(new Date()).getTime()}`.replace(/ /g, '_');
    (<HTMLInputElement>document.getElementById('sessionName')).value = userInfo.fromId;
    (<HTMLInputElement>document.getElementById('fromId')).value = userInfo.fromId;
    (<HTMLInputElement>document.getElementById('toId')).value = userInfo.toId;

    (<HTMLInputElement>document.getElementById('newUserId')).value = userInfo.usrTkn;

    if (+userInfo.userType === 1) {
      register(userInfo.toId, userInfo.fromId, userInfo.name, userInfo.userType);
      statusRegister();
      sessionStorage.userInfo = JSON.stringify(userInfo);
    }

  }

}