<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.UserRegistrationApprovalList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="pt-2 table-responsive" *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Full Name </th>
                                                        <!-- <th>Last Name</th> -->
                                                        <!-- <th>Email Id</th> -->
                                                        <th>Program Name</th>
                                                        <th>Contact Details</th>
                                                        <th>Application Form</th>
                                                        <th>Uploaded Documents</th>
                                                        <th>Edit/Update Application</th>
                                                        <th>Verification Status</th>
                                                        <th>Comments</th>
                                                        <th>Application Status</th>
                                                        <th>Admission Status</th>
                                                        <th>Application Date</th>
                                                        <th>Action</th>

                                                        <!-- <th>Mobile Number</th>
                                                        <th>Application Form</th>
                                                        <th>Action</th>
                                                        <th>Uploaded Documents</th> -->

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.firstname}} {{item.lastname}}</td>
                                                        <!-- <td>{{item.lastname}}</td> -->
                                                        <!-- <td>{{item.name}}</td> -->
                                                        <th>{{item.course_name}}</th>
                                                        <td>{{item.mobileno_code_name}} {{item.mobileno}}</td>


                                                        <td>
                                                            <button class="btn btn-save btn-sm"
                                                                data-bs-target="#applicationPopup"
                                                                data-bs-toggle="modal"
                                                                (click)="userDetailsMethod(item)">View
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm btn-info" data-bs-toggle="modal"
                                                                data-bs-target="#myModal"
                                                                (click)="getDocuments(item)">View</button>

                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm btn-info"
                                                                (click)="updateUserApplication(item)">Edit</button>
                                                        </td>

                                                        <td>
                                                            {{item.verification_status}}
                                                        </td>
                                                        <td><button class="btn btn-sm btn-save" *ngIf="item.verification_status == 'RE-VERIFY'" (click)="showComments(item.sentback_comments)">View Comments</button></td>
                                                        <td>
                                                            {{item.application_status}}
                                                        </td>
                                                        <td>
                                                            {{item.is_approved}}
                                                        </td>
                                                        <td>
                                                            {{item.application_date}}
                                                        </td>
                                                        <td style="width:8% !important;">
                                                            <button class="btn btn-save btn-sm" data-bs-toggle="modal"
                                                                data-bs-target="#reason" (click)="userDataObj(item)"
                                                                [attr.disabled]="(item?.verification_status=='VERIFIED') && (item?.application_status=='SHORTLISTED') && (item?.is_approved=='ADMISSIBLE')?true:null"><i
                                                                    class="fas fa-edit"></i> Action</button>



                                                            <!-- <div class="button-group d-none">
                                                                <button class="btn btn-success btn-sm"
                                                                    (click)="ApproveUser(1,item)">Admissible </button>
                                                                <button class="btn btn-primary btn-sm"
                                                                    data-bs-toggle="modal" data-bs-target="#reason"
                                                                    (click)="reject(3,item)">Feedback </button>
                                                                <button class="btn btn-danger btn-sm"
                                                                    data-bs-toggle="modal" data-bs-target="#reason"
                                                                    (click)="reject(2,item)">Not
                                                                    Admissible</button>
                                                            </div> -->
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" tabindex="-1" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="close()"></button>
            </div>
            <div class="modal-body">
                <table datatable id="DataTables_Table_0" class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                    style="width:100%">
                    <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                        <tr>
                            <th> Education Supporting Documents </th>
                            <!-- <th>High School Transcript</th>
                            <th>National ID/Passport</th> -->
                            <!-- <th>Passport Size Photograph</th> -->
                            <th>Portfolio</th>
                            <!-- <th>Application Essay</th> -->


                        </tr>
                        <!-- <tr></tr>
                            <tr></tr> -->
                    </thead>
                    <tbody>
                        <tr>
                            <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_1)">View</button></td>
                            <!-- <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_2)">Download</button></td>
                            <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_3)">Download</button></td> -->
                            <!-- <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_4)">View</button></td> -->
                            <td>
                                <b *ngIf="uploadRes?.upload_3 == null">Not Uploaded</b>
                                <button class="btn btn-info btn-sm" *ngIf="uploadRes?.upload_3 !=null"
                                    (click)="download(uploadRes?.upload_3)">View</button>
                            </td>
                            <!-- <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_5)">Download</button></td> -->





                        </tr>
                        <!-- <tr>
                                <td>dd</td>
                            </tr>
                            <tr>
                                <td>ff</td>
                            </tr> -->
                    </tbody>
                </table>
            </div>
            <!-- <div *ngIf="audioData">
                    <audio controls id="audioPause">
                        <source [src]="audioList">
                    </audio>
                </div> -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="md_close"
                    (click)="close()">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save</button> -->
            </div>
        </div>
    </div>
</div>

<!-- <button data-bs-target="reason" data-bs-toggle="modal" id="rejectReason"></button> -->
<div class="modal" tabindex="-1" id="reason">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="Close" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="StatusForm" (onSubmit)="callApiAndUpdateUI(StatusForm)">
                    <div class="row">
                        <!-- <div class="col-xl-12"> -->
                        <div class="col-xl-6">
                            <label class="form-label">Verification Status</label>
                            <select class="form-control form-control-sm"
                                [attr.disabled]="Verification == '5' ? true:null || userObj?.verification_status=='VERIFIED'?true:null"
                                [(ngModel)]="Verification" #vs formControlName="VERIFICATION_STATUS">
                                <!-- <option value="">Select</option> -->
                                <option value="0">Unverified</option>
                                <option value="5">Verified</option>
                                <option value="3">Follow Up</option>

                            </select>

                        </div>
                        <div class="col-xl-6">
                            <label class="form-label">Shortlisting Status</label>
                            <select class="form-control form-control-sm" formControlName="APPLICATION_STATUS"
                                [attr.disabled]="vs.value !='5'?true:null || ss.value == '1'?true:null  || userObj?.application_status=='SHORTLISTED'?true:null"
                                [(ngModel)]="shortListing" #ss>
                                <option value="0">Select</option>
                                <option value="1">Shortlisted</option>
                                <option value="2">Not Shortlisted</option>
                            </select>

                        </div>
                        <!-- <div class="col-xl-6">
                            <label class="form-label">Admission Status</label>
                            <select class="form-control form-control-sm pt-2" [(ngModel)]="admissionStatus"
                                formControlName="IS_APPROVED"
                                [attr.disabled]="ss.value !='1'?true:null || admissionStatus==1?true:null || userObj?.is_approved=='ADMISSIBLE'?true:null">
                            
                                <option value="0">Select</option>
                                <option value="1">Admissible</option>
                                <option value="2">Not Admissible</option>
                            </select>
                        </div> -->

                    </div>
                    <div class="mb-3" *ngIf="Verification == 3">
                        <label for="exampleFormControlTextarea1" class="form-label">Comments</label><span
                            class="text-danger">*</span>
                        <textarea class="form-control" [(ngModel)]="comments" id="exampleFormControlTextarea1" rows="5"
                            formControlName="COMMENTS"></textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="close()"
                    id="Close">Close</button>
                <button type="button" class="btn btn-save" (click)="callApiAndUpdateUI(StatusForm)">Submit</button>

            </div>
        </div>
    </div>
</div>


<!-- --------------------------------APPLICATION FORM POPUP------------------------------------ -->
<div class="modal" tabindex="-1" id="applicationPopup">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Application Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="ClosePopup"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div id="printtable" class="pt-5">


                    <table width="980" cellspacing="0" cellpadding="0" style="margin: auto;">
                        <tbody>
                            <tr>
                                <td>
                                    <table width="980" border="0" cellspacing="10" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <td height="" style="text-align: center; " valign="middle"><img
                                                        src="../../../../assets/img/ouk-logo-new.png" width="200"
                                                        alt=""></td>
                                                <td rowspan="4">
                                                    <div class="pt-2 d-flex" style="width: 150px; height: 150px;">
                                                        <img [src]="userDetails?.result?.upload_4"
                                                            class="img-fluid m-auto"
                                                            style="margin: auto; max-height: 100%;" alt="">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    <table width="100%">
                                                        <tr>
                                                            <td width="35%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>P.O. BOX</b> 2440-00606 NAIROBI, KENYA
                                                                </p>
                                                            </td>
                                                            <td width="35%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>TELEPHONE:</b> 0202000211/0202000212
                                                                </p>
                                                            </td>
                                                            <td width="30%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>EMAIL:</b> admissions@ouk.ac.ke
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    <h4 style="margin-top: 0; margin-bottom: 10px; font-weight: 600;">
                                                        OUK Admissions Office</h4>
                                                    <h5 style="color: #fe7f50; font-weight: 500">STUDENT APPLICATION
                                                        ONLINE FORM</h5>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </td>

                            </tr>


                            <tr>
                                <td colspan="2" style="padding: 10px">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="border-top:2px dashed #333; font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(A) PERSONAL DETAILS</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>First Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.firstname}}</td>
                                                <td width="24%"><b>Middle Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.middlename}}</td>

                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Last Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.lastname}}</td>
                                                <td><b>Country of Residence</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.country_of_residence}}</td>
                                            </tr>

                                            <tr>

                                                <td *ngIf="userDetails?.result?.counties_id !=0"><b>County</b></td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0">
                                                    {{userDetails?.result?.counties_name}}</td>


                                                <td><b>Postal Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.postal_address}} </td>


                                            </tr>
                                            <tr>

                                                <td *ngIf="userDetails?.result?.subcounties_id !=0"><b>Sub County</b></td>
                                                <td *ngIf="userDetails?.result?.subcounties_id !=0" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.result?.subcounties_id !=0">
                                                    {{userDetails?.result?.sub_counties_name}}</td>


                                                    <td *ngIf="userDetails?.result?.counties_constituencies_id !=0"><b>Constituency</b></td>
                                                    <td *ngIf="userDetails?.result?.counties_constituencies_id !=0" width="2%"
                                                        style="text-align: center;">:</td>
                                                    <td *ngIf="userDetails?.result?.counties_constituencies_id !=0">
                                                        {{userDetails?.result?.con_counties_name}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>Physical Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.physical_address}}</td>
                                                <td><b>Postal Code</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.postal_code}}</td>



                                            </tr>

                                            <tr>
                                                <td><b>Town/City</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.nearest_town}}</td>
                                                <td width="24%"><b>Current Email</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.username}}</td>


                                            </tr>

                                            <tr>
                                                <td><b>Mobile Number 1</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.mobileno_code_name}}
                                                    {{userDetails?.result?.mobileno}}</td>
                                                <td><b>Mobile Number 2</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.mobileno2_code_name}}
                                                    {{userDetails?.result?.mobileno2}}</td>

                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(B) Biographical Information</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Gender</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.gender_name}}</td>
                                                <td width="24%"><b>Date of Birth</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.dob}}</td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Marital Status</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.maritalstatus_name}}</td>
                                                <td width="24%"><b>Birth Country</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.birth_country}}</td>
                                            </tr>
                                            <tr>

                                                <td><b>Nationality</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.nan_code}}</td>
                                                <td><b>Identification Document Type</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.id_passport_type_name}}</td>
                                            </tr>
                                            <tr>


                                                <td><b>{{userDetails?.result?.id_passport_type_name}}</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td colspan="4">{{userDetails?.result?.id_passport}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(C) program selection</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Programme</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.course_name}}</td>
                                                <td><b>Application Type</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.APPLICATION_TYPE_NAME}}</td>
                                                <!-- <td><b>Is your placement
                                                        to OUK by KUCCPS?</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.IS_KUCCPS == true ? 'Yes':'No'}}</td> -->
                                            </tr>
                                            <tr>
                                              
                                                <td><b>English</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.ENGLISH_PROFICIENCY_NAME}}</td>

                                                <!-- <td width="2%" style="text-align: center;">:</td>
                                                <td></td> -->
                                            </tr>
                                            <tr *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                <td><b>Programme Cluster
                                                    </b></td>
                                            </tr>
                                            <tr *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                <td colspan="6">
                                                    <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                                        <tbody>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>Subject Name(eg MAT)</th>
                                                                <th>Grade obtained</th>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 1</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT1_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT1_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 2</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT2_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT2_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 3</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT3_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT3_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 4</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT4_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT4_GRADE}}
                                                                </td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                          

                                            <tr>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1"><b>Basic
                                                        Education System</b></td>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                    {{userDetails?.get_cluster?.BASIC_EDUCATION_SYSTEM_NAME ||
                                                    userDetails?.get_cluster?.BASIC_EDUCATION_SYSTEM_OTHER}}</td>
                                            </tr>
                                            <tr>

                                                <td><b>Computing Skills</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.COMPUTING_SKILLS_PROFICIENCY_NAME}}</td>
                                                <!-- <td><b>Mode of Study</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td colspan="4">{{userDetails?.get_cluster?.MODE_OF_STUDY_NAME}}</td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="border-bottom:1px dashed #333; font-size:13px;">
                                        <tbody>
                                            <tr>
                                            <tr style="border-bottom:0px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(D) Previous Education Details :</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div *ngFor="let item of userDetails?.lst">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>
                                                <tr>
                                                    <td width="24%"><b>Institution Name</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.INSTITUTION_NAME}}</td>
                                                    <td width="24%"><b>Country</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.EDUCATION_COUNTRY}}</td>
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Level of Study</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%" colspan="4">{{item.LEVEL_OF_STUDY}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Start Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.FROM_PERIOD | date:'yyyy-MM-dd'}}</td>
                                                    <td width="24%"><b>End Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.TO_PERIOD | date:'yyyy-MM-dd'}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Examination Number e.g. KCSE index number</b>
                                                    </td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.EXAM_REG_NO}}</td>
                                                    <td width="24%"><b>Qualifications Attained</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.QUALIFICATION}}</td>

                                                </tr>
                                                <!-- <tr>
                                            <td><b>Address</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td > {{item.ADDRESS}}</td>
                                            <td><b>Additional Information</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{item.ADDITIONAL_INFORMATION}}</td>
                                        </tr> -->

                                            </tbody>
                                        </table>
                                    </div>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(E) Documents Uploaded :</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <!-- <td><b>Upload Previous Supporting Certificates</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td></td> -->
                                                <td><b>Are you applying for Recognition of Prior Learning (RPL)?</b>
                                                </td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.is_rpl == true ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <!-- <tr>
                                        <td><b>Marital Status</b></td>
                                        <td width="2%" style="text-align: center;">:</td>
                                        <td>{{userDetails?.result?.mobile_no_emergency}}</td>
                                        <td><b>Are you applying for Recognition of Prior Learning (RPL)?</b>
                                        </td>
                                        <td width="2%" style="text-align: center;">:</td>
                                        <td>{{userDetails?.result?.address_of_emergency}}</td>
                                    </tr> -->
                                            <tr>
                                                <!-- <td><b>Upload your Portfolio Documents</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td></td> -->
                                        </tbody>
                                    </table>
                                   
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6">
                                        <tbody>
                                            <tr style="border-bottom:1px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(F) Working Experience</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngFor="let item of userDetails?.lstt">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>

                                                <tr>
                                                    <td width="24%"><b>Company/Employer</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_EMPLOYER}}</td>
                                                    <td width="24%"><b>Designation</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_DESIGNATION}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Start Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_FROM_YEAR |
                                                        date:'yyyy-MM-dd'}}</td>
                                                    <td width="24%"><b>End Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_TO_YEAR | date:'yyyy-MM-dd'}}
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>



                                    <table width="100%" border="0" cellspacing="0" cellpadding="6">
                                        <tbody>

                                            <tr style="border-bottom:1px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(G) Recommendations :</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngFor="let item of userDetails?.lst_recommandations">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>
                                                <tr>
                                                    <td width="24%"><b>Full Name</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.NAME}}</td>
                                                    <td width="24%"><b>Organization</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.ORGANIZATION}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Mobile Number</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.MOBILE_NUMBER}}</td>
                                                    <td width="24%"><b>City/Town</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.CITY_TOWN}}</td>
                                                    <!-- <td width="24%"><b>Nationality</b></td>
                                        <td width="2%" style="text-align: center;">:</td>
                                        <td width="24%"> {{userDetails?.result?.sponsors_email}}</td> -->
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Nationality</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%"> {{item.RECOMMENDATION_NATIONALITY}}</td>
                                                    <td width="24%"><b>Email Address</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%" colspan="4">{{item.EMAIL_ADDRESS}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                    style="font-size:13px;">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                <h5><b>(H) Next of Kin/Emergency Contact :</b></h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Full Name</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{userDetails?.result?.name_of_emergency}}</td>
                                            <td><b>Relationship</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{userDetails?.result?.relationshipname_of_emergency_name}}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Telephone</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{userDetails?.result?.emergency_mobileno_code_name}}
                                                {{userDetails?.result?.mobile_no_emergency}}</td>
                                            <td><b>Email Address</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{userDetails?.result?.emailid_of_emergency}}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Postal Address</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{userDetails?.result?.address_of_emergency}}</td>
                                            <td><b>Postal Code</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{userDetails?.result?.postalcode_of_emergency}}</td>

                                        </tr>
                                        <tr>
                                            <td><b>City/Town</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{userDetails?.result?.
                                                nearest_town}}</td>
                                            <td><b>Country</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{userDetails?.result?.emergency_country}}</td>

                                        </tr>
                                        <tr>

                                            <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0">
                                                <b>County</b>
                                            </td>
                                            <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0" width="2%"
                                                style="text-align: center;">:</td>
                                            <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0">
                                                {{userDetails?.result?.counties_name_of_emergency}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="footer float-end pt-2">
                        <button id="Closemodal" class="btn btn-sm btn-danger"
                            data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                        <button class="btn btn-save btn-sm" (click)="downloadForm()"> Download</button>
                    </div>
                </div>
                <!-- <div class="footer float-end pt-2">
                        <button class="btn btn-save btn-sm" (click)="downloadForm()"> Download</button>&nbsp;&nbsp;
                    </div> -->
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
        </div>
    </div>
</div>
<!-- --------------------------------APPLICATION FORM POPUP END------------------------------------ -->

<div class="modal" tabindex="-1" id="editPopup">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <form action="" [formGroup]="signUpForm">
                    <!-- *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)" -->
                    <div class="row">
                        <div class="col-xl-2">
                            <div class="d-flex flex-column" style="max-width: 200px;">
                                <ul class="nav nav-tabs" id="myTab" role="tablist" style="cursor: pointer;">
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#home-tab" class="nav-link active" id="home"
                                            data-bs-toggle="tab" role="tab" aria-controls="home-tab"
                                            aria-selected="true">Personal Details</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#Biological-info-tab" id="Biological-info" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Biological-info-tab"
                                            aria-selected="false">Biographical
                                            Information</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#application-info-tab" id="application-info" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="application-info-tab"
                                            aria-selected="false">Program Selection</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#Education-Bg-tab" id="Education-Bg" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Education-Bg-tab"
                                            aria-selected="false">Education
                                            Background</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#doc-upload-tab" id="doc-upload" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="doc-upload-tab"
                                            aria-selected="false">Documents Upload</a>
                                    </li>
                                   
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#Working-Exp-tab" id="Working-Exp" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Working-Exp-tab"
                                            aria-selected="false">Work
                                            Experience</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#Recommendations-tab" id="Recommendations" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Recommendations-tab"
                                            aria-selected="false">Recommendations</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#Next-of-Kin-tab" id="Next-of-Kin" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Next-of-Kin-tab"
                                            aria-selected="false">Next
                                            of
                                            Kin/ Emergency Contact</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a data-bs-target="#Review-tab" id="Review" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Review-tab"
                                            aria-selected="false">Review and Submit</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-10">
                            <div class="tab-content bg-white" id="myTabContent">
                                <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="home"
                                    id="home-tab">
                                    <div class="row">
                                        <div class="col-md-12 m-auto">
                                            <div class="card border-0 card-shadow h-100">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Name</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">First name <span
                                                                        class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="FIRSTNAME"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Middle name

                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        formControlName="MIDDLENAME"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Last name <span
                                                                        class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        formControlName="LASTNAME"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="card_title mt-2 mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Address</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Country of
                                                                    Residence
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        (change)="changeCountry($any($event.target)?.value,'personalDetailsCountry')"
                                                                        class="form-select form-select-sm"
                                                                        formControlName="COUNTRY_ID">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor='let item of country'
                                                                            [value]='item.COUNTRY_ID'>
                                                                            {{item.COUNTRY_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" *ngIf="countyDrpdown">
                                                                <div class="col-md-4">County
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                    (change)="subCounties($any($event.target).value)"
                                                                        class="form-select form-select-sm"
                                                                        formControlName="COUNTIES_ID">
                                                                        <option value="">Select
                                                                        </option>
                                                                        <option *ngFor='let item of County'
                                                                            [value]='item.COUNTIES_CODE'>
                                                                            {{item.COUNTIES_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="row mb-2" *ngIf="countyDrpdown">
                                                                <div class="col-md-4">Sub County
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        class="form-select form-select-sm"
                                                                        formControlName="SUBCOUNTIES_ID">
                                                                        <option value="">Select
                                                                        </option>
                                                                        <option
                                                                            *ngFor='let item of subCounty'
                                                                            [value]='item.sub_counties_id'>
                                                                            {{item.sub_counties_name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" *ngIf="countyDrpdown">
                                                                <div class="col-md-4">Constituency
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        class="form-select form-select-sm"
                                                                        formControlName="COUNTIES_CONSTITUENCIES_ID">
                                                                        <option value="">Select
                                                                        </option>
                                                                        <option
                                                                            *ngFor='let item of Constituency'
                                                                            [value]='item.con_counties_id'>
                                                                            {{item.con_counties_name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Postal Address
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="P.O.Box"
                                                                        formControlName="POSTAL_ADDRESS">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Physical Address
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="PHYSICAL_ADDRESS">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Postal Code</div>
                                                                <div class="col-md-6"><input type="text" maxlength="10"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="POSTAL_CODE">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">City/Town</div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="NEAREST_TOWN"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                </div>
                                                            </div>
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Email</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Current Email
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="USERNAME">
                                                                </div>
                                                            </div>
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Telephone Numbers</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Mobile Number 1
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-3">

                                                                    <select class="form-select form-select-sm"
                                                                        formControlName="MOBILENO_CODE"
                                                                        [(ngModel)]="countryCode">
                                                                        <option *ngFor="let item of country"
                                                                            [value]="item?.COUNTRY_ID">
                                                                            {{item?.COUNTRY_CONNECTING_CODE}}
                                                                        </option>
                                                                    </select>



                                                                    <!-- <input type="text" [(ngModel)]="countryCode" [ngModelOptions]="{standalone:true}" disabled class="form-control form-control-sm" > -->
                                                                </div>&nbsp;
                                                                <div class="col-md-4">
                                                                    <input type="text"
                                                                        placeholder="Ex : 712345678, do not start with 0"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="MOBILENO"
                                                                        (blur)="mobileValidation($event,1)"
                                                                        (keypress)="onKeyPress($event)" maxlength="12">
                                                                    <!-- <span class="text-blue"><small>Include
                                                                        your country code in the
                                                                        designated
                                                                        field.</small></span> -->
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Mobile Number 2
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <select class="form-select form-select-sm"
                                                                        formControlName="MOBILENO2_CODE"
                                                                        [(ngModel)]="countryCodeOne">
                                                                        <option *ngFor="let item of country"
                                                                            [value]="item.COUNTRY_ID">
                                                                            {{item.COUNTRY_CONNECTING_CODE}}
                                                                        </option>
                                                                    </select>

                                                                    <!-- <input type="text" disabled class="form-control form-control-sm" [(ngModel)]="countryCode" [ngModelOptions]="{standalone:true}"> -->
                                                                </div>&nbsp;
                                                                <div class="col-md-4">
                                                                    <input type="text"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="Ex : 712345678, do not start with 0"
                                                                        formControlName="MOBILENO2"
                                                                        (blur)="mobileValidation($event,2)"
                                                                        (keypress)="onKeyPress($event)" maxlength="12">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 text-end">
                                                            <div id="btnNext">
                                                                <a class="btn btn-sm admin-btn btn-sm px-4 rounded-0 btnNext"
                                                                    (click)="nextTab()">Next</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer bg-white mt-3">
                                                    <div style="width: 35%" class="mb-2"><img
                                                            src="../../../../assets/img/OUK-Admissions-logo.png"
                                                            class="img-fluid" alt=""></div>
                                                    <p class="small">OUK is a public university that was
                                                        established and
                                                        Chartered in 2023. We aim
                                                        to provide
                                                        inclusive and flexible high quality higher
                                                        education
                                                        opportunities
                                                        suited for the global
                                                        citizens in the
                                                        21st century. At OUK admissions, we recruit and
                                                        enroll diverse class
                                                        of students.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Biological-info-tab" role="tabpanel"
                                    aria-labelledby="Biological-info">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body ">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Biographical
                                                                    Information
                                                                </h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Gender <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select class="form-select form-select-sm"
                                                                        formControlName="GENDER">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor="let item of gender"
                                                                            [value]='item.DICTIONARYID'>
                                                                            {{item.DICTIONARYNAME}}
                                                                        </option>
                                                                    </select>

                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Date of Birth
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <div class="form-group">
                                                                        <label>Year</label>
                                                                        <select class="form-select form-select-sm"
                                                                            [(ngModel)]="selectedYear"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            (ngModelChange)="generateDropdowns()"
                                                                            (change)="clearMonthDay()">
                                                                            <option *ngFor="let year of years"
                                                                                [value]="year">{{ year
                                                                                }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-2">
                                                                    <div class="form-group">
                                                                        <label>Month</label>
                                                                        <select class="form-select form-select-sm"
                                                                            [(ngModel)]="selectedMonth"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            (ngModelChange)="generateDropdowns()">
                                                                            <option *ngFor="let month of months"
                                                                                [value]="month">{{ month
                                                                                }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="form-group">
                                                                        <label>Day</label>
                                                                        <select class="form-select form-select-sm"
                                                                            [(ngModel)]="selectedDay"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            (change)="validateAge()">
                                                                            <option *ngFor="let day of days"
                                                                                [value]="day">{{ day }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Marital Status
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        formControlName="MARTIALSTATUS"
                                                                        class="form-select form-select-sm">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor='let item of MartialStatus'
                                                                            [value]='item.DICTIONARYID'>
                                                                            {{item.DICTIONARYNAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Birth Country
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        class="form-select form-select-sm"
                                                                        [(ngModel)]="birthCountry"
                                                                        formControlName="BIRTH_COUNTRY_ID">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor='let item of country'
                                                                            [value]='item.COUNTRY_ID'>
                                                                            {{item.COUNTRY_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Passport Size
                                                                    Photograph <small>(only
                                                                        allow .jpeg or .png)</small>
                                                                </div>

                                                                <div class="col-md-6">
                                                                    <div class="input-group">
                                                                        <input type="file" formControlName="UPLOAD_4"
                                                                            (change)="changeFile($event,4)"
                                                                            class="form-control"
                                                                            placeholder="Recipient's username"
                                                                            aria-label="Recipient's username"
                                                                            aria-describedby="basic-addon2"
                                                                            #fileInputRefPassport>
                                                                        <span class="input-group-text"
                                                                            style="cursor: pointer;" id="basic-addon2"
                                                                            (click)="removeFile('3')">Remove</span>
                                                                    </div>
                                                                    <p *ngIf="upload_2 !=''">
                                                                        {{upload_2}}</p>

                                                                </div>







                                                                <!-- <div class="col-md-6">
                                                                <div class="input-group mb-3">
                                                                    <input type="file"
                                                                        formControlName="UPLOAD_4"
                                                                        (change)="changeFile($event,4)"
                                                                        class="form-control form-control-sm"><br>
                                                                  
                                                                </div>
                                                            </div> -->
                                                                <div class="card_title mt-2 mb-3 px-2 py-1 text-white">
                                                                    <h6 class="mb-0">Citizenship
                                                                        Information
                                                                    </h6>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Nationality
                                                                        <span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <select class="form-select form-control-sm"
                                                                            [(ngModel)]="nationalityKenya"
                                                                            formControlName="NATIONALITY">
                                                                            <option value="0">Select
                                                                            </option>
                                                                            <option *ngFor="let item of nationalityList"
                                                                                [value]="item.NAN_ID">
                                                                                {{item.NAN_CODE}}
                                                                            </option>
                                                                        </select>

                                                                        <!-- <input
                                                                        type="text"
                                                                        formControlName="NATIONALITY"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                        class="form-control form-control-sm"> -->
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Identification Document Type
                                                                        <span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"> <select
                                                                            (change)="documentTypeId($event)"
                                                                            class="form-select form-select-sm"
                                                                            formControlName="ID_PASSPORT_TYPE">
                                                                            <option value="0">Select
                                                                            </option>
                                                                            <option
                                                                                *ngFor="let item of passportTypeList"
                                                                                [value]="item.id">{{item.name}}</option>
                                                                            <!-- <option value="1">National
                                                                                ID</option>
                                                                            <option value="2">Passport
                                                                            </option>
                                                                            <option value="3">Birth
                                                                                Certificate </option> -->

                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <div class="col-md-4"> {{selectedValueText ||
                                                                        userDetails?.result?.id_passport_type_name}}
                                                                        Number
                                                                        <span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            (keypress)="onlyAlphanumerics($event)"
                                                                            formControlName="ID_PASSPORT" maxlength="15"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 mt-3">
                                                                    <div id="btnPrevious"><a
                                                                            class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                            (click)="previousTab()">Previous</a>
                                                                    </div>
                                                                    <div id="btnNext"><a
                                                                            class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                            (click)="nextTab()">Next</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-footer bg-white mt-3">
                                                                <div style="width: 35%" class="mb-2">
                                                                    <img src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                        class="img-fluid" alt="">
                                                                </div>
                                                                <p class="small">OUK is a public
                                                                    university
                                                                    that was
                                                                    established and Chartered in 2023.
                                                                    We
                                                                    aim
                                                                    to provide
                                                                    inclusive and flexible high quality
                                                                    higher education
                                                                    opportunities suited for the global
                                                                    citizens in the
                                                                    21st century. At OUK admissions, we
                                                                    recruit and enroll
                                                                    diverse class of students.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="application-info-tab" role="tabpanel"
                                aria-labelledby="application-info">
                                <div class="card border-0 card-shadow h-100">
                                    <div class="card-body assesment pt-0 style-1 ">
                                        <div class="row">
                                            <div class="col-md-12 m-auto">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body">
                                                        <div class="card_title mb-3 px-2 py-1 text-white">
                                                            <h6 class="mb-0">What would you like to
                                                                study?</h6>
                                                        </div>

                                                        <!-- <div class="row mb-2 ">
                                                            <div class="col-md-4">Is your placement
                                                                to OUK by KUCCPS? <span class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <select formControlName="IS_KUCCPS"
                                                                    class="form-select form-select-sm">
                                                                    <option value="true">YES</option>
                                                                    <option value="false">NO</option>
                                                                </select>
                                                            </div>
                                                        </div> -->
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Application Type
                                                                <span class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <select class="form-select form-select-sm"
                                                                    (change)="applicationType($any($event.target).value)"
                                                                    formControlName="APPLICATION_TYPE"
                                                                    aria-label="Default select example">
                                                                    <option value="0">Select
                                                                    </option>
                                                                    <option value="1">Undergraduate
                                                                    </option>
                                                                    <option value="2">Postgraduate
                                                                    </option>
                                                                </select>


                                                                <span class="text-blue"><small>(e.g.
                                                                        Undergraduate or
                                                                        Postgraduate)</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Programme <span
                                                                    class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <select [(ngModel)]="courseId"
                                                                    (change)="loadClusterDetails($event)"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let item of  courseList"
                                                                        [value]="item.COURSE_ID">
                                                                        {{item.COURSE_NAME}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="clusters">
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Basic
                                                                    Education
                                                                    System

                                                                </div>
                                                                <div class="col-md-6">


                                                                    <select [(ngModel)]="basicEduSystem"
                                                                        [ngModelSystem]="{standalone:true}"
                                                                        class="form-select form-select-sm"
                                                                        formControlName="BASIC_EDUCATION_SYSTEM"
                                                                        aria-label="Default select example">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option value="1">KCSE
                                                                        </option>
                                                                        <option value="2">IGCSE
                                                                        </option>
                                                                        <option value="3">GCSE
                                                                        </option>
                                                                        <option value="4">IB
                                                                        </option>
                                                                        <option value="5">Others
                                                                        </option>
                                                                    </select>
                                                                    <div class="pt-2" *ngIf="basicEduSystem == '5'">
                                                                        <input type="text"
                                                                            class="form-control form-control-sm"
                                                                            formControlName="BASIC_EDUCATION_SYSTEM_OTHER"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                    </div>
                                                                    <span class="text-blue"><small>(e.g.
                                                                            education system
                                                                            that
                                                                            describes your secondary
                                                                            school
                                                                            education
                                                                            journey)</small></span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Mean Grade

                                                                </div>
                                                                <div class="col-md-6">
                                                                    <!-- [(ngModel)]="grade"  -->
                                                                    <input type="text" [(ngModel)]="meanGrade"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        (blur)="validateGrade($event)"
                                                                        formControlName="MEAN_GRADE" maxlength="2">
                                                                </div>
                                                            </div>

                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Programme
                                                                    Cluster
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <span class="text-blue"><small>(for
                                                                            undergraduates
                                                                            provide
                                                                            relevant cluster
                                                                            subjects
                                                                            and
                                                                            grades based on
                                                                            KUCCPS
                                                                            guidelines)</small></span>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <table class="table table-bordered">
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>Subject Name(eg MAT)
                                                                            </th>
                                                                            <th>Grade obtained</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>CLUSTER SUBJECT 1
                                                                            </td>
                                                                            <!-- <td><input type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                formControlName="CLUSTER_SUBJECT1_NAME"
                                                                                class="form-control form-control-sm">
                                                                        </td>
                                                                        <td><input type="text"
                                                                                formControlName="CLUSTER_SUBJECT1_GRADE"
                                                                                class="form-control form-control-sm">
                                                                        </td> -->
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-control-sm"
                                                                                    formControlName="CLUSTER_SUBJECT1_NAME">
                                                                                    <option value=''>
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of clusterDropdowns[0]"
                                                                                        [value]="item.CLUSTERDTL_ID">
                                                                                        {{item.CLUSTER_SUBJECT_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-control-sm"
                                                                                    formControlName="CLUSTER_SUBJECT1_GRADE">
                                                                                    <option value=''>
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of ClusterGradeList"
                                                                                        [value]="item.CLUSTER_GRADE_ID ">
                                                                                        {{item.CLUSTER_GRADE_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>CLUSTER SUBJECT 2
                                                                            </td>
                                                                            <!-- <td><input type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                formControlName="CLUSTER_SUBJECT2_NAME"
                                                                                class="form-control form-control-sm">
                                                                        </td>
                                                                        <td><input type="text"
                                                                                formControlName="CLUSTER_SUBJECT2_GRADE"
                                                                                class="form-control form-control-sm">
                                                                        </td> -->
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-control-sm"
                                                                                    formControlName="CLUSTER_SUBJECT2_NAME">
                                                                                    <option value=''>
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of clusterDropdowns[1]"
                                                                                        [value]="item.CLUSTERDTL_ID">
                                                                                        {{item.CLUSTER_SUBJECT_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-control-sm"
                                                                                    formControlName="CLUSTER_SUBJECT2_GRADE">
                                                                                    <option value=''>
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of ClusterGradeList"
                                                                                        [value]="item.CLUSTER_GRADE_ID ">
                                                                                        {{item.CLUSTER_GRADE_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>CLUSTER SUBJECT 3
                                                                            </td>
                                                                            <!-- <td><input type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                formControlName="CLUSTER_SUBJECT3_NAME"
                                                                                class="form-control form-control-sm">
                                                                        </td>
                                                                        <td><input type="text"
                                                                                formControlName="CLUSTER_SUBJECT3_GRADE"
                                                                                class="form-control form-control-sm">
                                                                        </td> -->
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-control-sm"
                                                                                    formControlName="CLUSTER_SUBJECT3_NAME">
                                                                                    <option value=''>
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of clusterDropdowns[2]"
                                                                                        [value]="item.CLUSTERDTL_ID">
                                                                                        {{item.CLUSTER_SUBJECT_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-control-sm"
                                                                                    formControlName="CLUSTER_SUBJECT3_GRADE">
                                                                                    <option value=''>
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of ClusterGradeList"
                                                                                        [value]="item.CLUSTER_GRADE_ID ">
                                                                                        {{item.CLUSTER_GRADE_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>CLUSTER SUBJECT 4
                                                                            </td>
                                                                            <!-- <td><input type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                formControlName="CLUSTER_SUBJECT4_NAME"
                                                                                class="form-control form-control-sm">
                                                                        </td>
                                                                        <td><input type="text"
                                                                                formControlName="CLUSTER_SUBJECT4_GRADE"
                                                                                class="form-control form-control-sm">
                                                                        </td> -->
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-control-sm"
                                                                                    formControlName="CLUSTER_SUBJECT4_NAME">
                                                                                    <option value=''>
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of clusterDropdowns[3]"
                                                                                        [value]="item.CLUSTERDTL_ID">
                                                                                        {{item.CLUSTER_SUBJECT_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-control-sm"
                                                                                    formControlName="CLUSTER_SUBJECT4_GRADE">
                                                                                    <option value=''>
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of ClusterGradeList"
                                                                                        [value]="item.CLUSTER_GRADE_ID ">
                                                                                        {{item.CLUSTER_GRADE_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row mb-2">
                                                            <div class="col-md-4">English <span
                                                                    class="text-danger">*</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="dropdown">
                                                                    <select class="form-control form-control-sm"
                                                                        formControlName="ENGLISH_PROFICIENCY">
                                                                        <option value="0">Select
                                                                            Level
                                                                        </option>
                                                                        <option value="1">Beginner
                                                                        </option>
                                                                        <option value="2">
                                                                            Intermediate
                                                                        </option>
                                                                        <option value="3">Advanced
                                                                        </option>
                                                                        <option value="4">Mastery
                                                                        </option>
                                                                    </select>

                                                                </div>
                                                                <span class="text-blue small">(your
                                                                    language proficiency
                                                                    level in both speaking and
                                                                    writing)</span>
                                                            </div>

                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Computing Skills<span
                                                                    class="text-danger">*</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="dropdown">
                                                                    <select class="form-control form-control-sm"
                                                                        formControlName="COMPUTING_SKILLS_PROFICIENCY">
                                                                        <option value="0">Select
                                                                            Level
                                                                        </option>
                                                                        <option value="1">Beginner
                                                                        </option>
                                                                        <option value="2">
                                                                            Intermediate
                                                                        </option>
                                                                        <option value="3">Advanced
                                                                        </option>
                                                                        <option value="4">Mastery
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <span class="text-blue small">(describe
                                                                    your computer
                                                                    handling skills)</span>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="row mb-2">
                                                            <div class="col-md-4">Mode of Study
                                                                <span class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <select formControlName="MODE_OF_STUDY"
                                                                    class="form-control form-control-sm">
                                                                    <option value="0">Select
                                                                    </option>
                                                                    <option value="1">Fulltime
                                                                    </option>
                                                                    <option value="2">Part-time
                                                                    </option>
                                                                    <option value="3">Modular
                                                                    </option>
                                                                </select>
                                                            
                                                            </div>
                                                        </div> -->

                                                        <div class="col-12 mt-3">
                                                            <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                (click)="previousTab()">Previous</a>
                                                            <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                (click)="nextTab()">Next</a>
                                                        </div>
                                                    </div>
                                                    <div class="card-footer bg-white mt-3">
                                                        <div style="width: 35%" class="mb-2"><img
                                                                src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                class="img-fluid" alt=""></div>
                                                        <p class="small">OUK is a public university
                                                            that
                                                            was established
                                                            and Chartered in 2023. We
                                                            aim
                                                            to provide
                                                            inclusive and flexible high quality
                                                            higher
                                                            education
                                                            opportunities suited for the global
                                                            citizens in the
                                                            21st century. At OUK admissions, we
                                                            recruit
                                                            and enroll
                                                            diverse class of students.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="tab-pane fade" id="Education-Bg-tab" role="tabpanel"
                                    aria-labelledby="Education-Bg">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Education Background
                                                                </h6>
                                                            </div>
                                                            <div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Institution
                                                                        Name<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            [(ngModel)]="INSTITUTION_NAME"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Country</div>
                                                                    <div class="col-md-6"> <select
                                                                            class="form-control form-control-sm"
                                                                            [(ngModel)]="EDUCATION_COUNTRY_ID"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                            <option value="">Select
                                                                            </option>
                                                                            <option *ngFor='let item of country'
                                                                                [value]='item.COUNTRY_ID'>
                                                                                {{item.COUNTRY_NAME}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Level of
                                                                        Study<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <input type="text" [(ngModel)]="LEVEL_OF_STUDY"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            class="form-control form-control-sm"><span
                                                                            class="text-blue"><small>e.g.
                                                                                Primary School, High
                                                                                School, Certificate,
                                                                                Diploma,
                                                                                Degree,
                                                                                Masters</small></span>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Qualifications
                                                                        Attained<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <select
                                                                            (change)="yearValidate($any($event.target).value);clearOther($any($event.target).value)"
                                                                            class="form-select form-select-sm"
                                                                            [(ngModel)]="QUALIFICATION_ID"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                            <option value="">Select
                                                                            </option>
                                                                            <option value="1">KCSE
                                                                            </option>
                                                                            <option value="2">IGCSE
                                                                            </option>
                                                                            <option value="3">GCSE
                                                                            </option>
                                                                            <option value="4">IB
                                                                            </option>
                                                                            <option value="5">Others
                                                                            </option>
                                                                        </select>
                                                                        <div class="pt-2">
                                                                            <input class="form-control form-control-sm"
                                                                                [(ngModel)]="QUALIFICATION"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                *ngIf="QUALIFICATION_ID == '5'"
                                                                                type="text"
                                                                                onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                        </div>

                                                                    </div>
                                                                    <!-- <div
                                                                    class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                                                    <button
                                                                        class="btn btn-danger btn-sm "
                                                                        (click)="remove(i)">Remove</button>
                                                                </div> -->

                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Start
                                                                        Date<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>


                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Year</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedYearStart"
                                                                                (change)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <!-- <option value="0">YYYY</option> -->
                                                                                <option *ngFor="let year of years"
                                                                                    [value]="year">{{
                                                                                    year }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-2">
                                                                        <div class="form-group">
                                                                            <label>Month</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedMonthStart"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let month of months"
                                                                                    [value]="month">{{
                                                                                    month }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Day</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedDayStart"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let day of startDays"
                                                                                    [value]="day">{{ day
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-md-6 d-none"> 
                                                                    <input
                                                                        type="date"
                                                                        [(ngModel)]="FROM_PERIOD"
                                                                        (keydown)="disableDate()"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        class="form-control form-control-sm"
                                                                        id="fromDate"
                                                                        (change)="validateStartDate('')">
                                                                </div> -->
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">End Date<span
                                                                            class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Year</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedYearEnd"
                                                                                (change)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let year of years"
                                                                                    [value]="year">{{
                                                                                    year }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-2">
                                                                        <div class="form-group">
                                                                            <label>Month</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedMonthEnd"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let month of months"
                                                                                    [value]="month">{{
                                                                                    month }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Day</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedDayEnd"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let day of days"
                                                                                    [value]="day">{{ day
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>




                                                                    <!-- <div class="col-md-6"> <input
                                                                        type="date"
                                                                        [(ngModel)]="TO_PERIOD"
                                                                        (keydown)="disableDate()"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        class="form-control form-control-sm"
                                                                        id="toDate"
                                                                        (change)="validateEndDate('')">
                                                                 
                                                                </div> -->
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Examination
                                                                        Number
                                                                        e.g. KCSE index
                                                                        number<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            [(ngModel)]="EXAM_REG_NO"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>




                                                                <!-- <div class="row">
                                                        <div class="col-12 text-center">
                                                              
                                                            <button type="button"
                                                                class="btn btn-sm admin-btn btn-sm px-4 rounded-0" (click)="add()">Add</button>
                                                        </div>
                                                    </div> -->
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 text-center">
                                                                    <button type="button"
                                                                        class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                        (click)="add()">Add and Save</button>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="educationData.length">
                                                                <table
                                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small table-responsive"
                                                                    style="width:100%">
                                                                    <thead class="top"
                                                                        style="background-color: #624FD7; color:#ffffff;">
                                                                        <tr>
                                                                            <th>Institution</th>
                                                                            <!-- <th>Country</th> -->
                                                                            <th>Level of Study</th>
                                                                            <th>Qualifications Attained
                                                                            </th>
                                                                            <th>Start Date</th>
                                                                            <th>End Date</th>
                                                                            <th>KCSE index
                                                                                number</th>

                                                                            <th>Action</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let row of educationData;let i = index">
                                                                            <td>{{row?.INSTITUTION_NAME}}
                                                                            </td>
                                                                            <!-- <td><select disabled
class="form-control form-control-sm"
[(ngModel)]="row.EDUCATION_COUNTRY_ID" [ngModelOptions]="{standalone: true}"
>
<option value="">Select
</option>
<option
*ngFor='let item of country'
[value]='item.DICTIONARYID'>
{{item.DICTIONARYNAME}}
</option>
</select></td> -->
                                                                            <td>{{row?.LEVEL_OF_STUDY}}
                                                                            </td>
                                                                            <td> <select
                                                                                    *ngIf="row.QUALIFICATION_ID !='5'"
                                                                                    class="form-select form-select-sm"
                                                                                    disabled
                                                                                    [(ngModel)]="row.QUALIFICATION_ID"
                                                                                    [ngModelOptions]="{standalone: true}">
                                                                                    <option value="">
                                                                                        Select</option>
                                                                                    <option value="1">
                                                                                        KCSE</option>
                                                                                    <option value="2">
                                                                                        IGCSE</option>
                                                                                    <option value="3">
                                                                                        GCSE</option>
                                                                                    <option value="4">IB
                                                                                    </option>
                                                                                    <option value="5">
                                                                                        Others</option>
                                                                                </select>
                                                                                <div class="pt-2"
                                                                                    *ngIf="row.QUALIFICATION_ID =='5'">
                                                                                    <input type="text"
                                                                                        class="form-control form-control-sm"
                                                                                        disabled
                                                                                        [value]="row.QUALIFICATION">
                                                                                </div>

                                                                            </td>
                                                                            <td>{{row?.FROM_PERIOD}}
                                                                            </td>
                                                                            <td>{{row?.TO_PERIOD}}</td>
                                                                            <td>{{row.EXAM_REG_NO}}</td>

                                                                            <td style="display: flex;">
                                                                                <button class="btn btn-info btn-sm"
                                                                                    (click)="editdata(row,'edu')">Edit</button>
                                                                                &nbsp;&nbsp;
                                                                                <button class="btn btn-danger btn-sm "
                                                                                    (click)="remove(i)">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="text-center" *ngIf="!educationData.length">
                                                                No Records to display
                                                            </div>


                                                            <div class="col-12 mt-3">
                                                                <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                    (click)="previousTab()">Previous</a>
                                                                <!-- <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="nextTab()">Next</a> -->
                                                                <button
                                                                    class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="nextTab()"
                                                                    [disabled]="!educationData.length">Next</button>
                                                            </div>
                                                        </div>

                                                        <div class="card-footer bg-white mt-3">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="doc-upload-tab" role="tabpanel"
                                    aria-labelledby="doc-upload">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Education supporting
                                                                    documents upload</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-6">Upload CERTIFIED
                                                                    Copies
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>

                                                                <div class="col-md-6">
                                                                    <div class="input-group">
                                                                        <input type="file" formControlName="UPLOAD_1"
                                                                            (change)="changeFile($event,1)"
                                                                            class="form-control"
                                                                            placeholder="Recipient's username"
                                                                            aria-label="Recipient's username"
                                                                            aria-describedby="basic-addon2"
                                                                            #fileInputRefupload1>
                                                                        <span class="input-group-text"
                                                                            style="cursor: pointer;" id="basic-addon2"
                                                                            (click)="removeFile('1')">Remove</span>
                                                                    </div>
                                                                    <div class="text-blue"><small>(The
                                                                            previous education
                                                                            support
                                                                            documents should be scanned
                                                                            and
                                                                            uploaded.)</small>
                                                                    </div>
                                                                    <p *ngIf="upload_1 !=''">
                                                                        {{upload_1}}</p>
                                                                </div>


                                                            </div>

                                                            <div class="row mb-2">
                                                                <div class="col-md-6">Are you applying
                                                                    for
                                                                    <b>Recognition of
                                                                        Prior Learning (RPL)?</b> <span
                                                                        class="text-danger"> *</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select class="form-control form-control-sm"
                                                                        formControlName="IS_RPL">
                                                                        <option value="null">Select</option>
                                                                        <option value="true">Yes</option>
                                                                        <option value="false">No</option>
                                                                    </select>
                                                                    <!-- <small>(dropdown: YES/NO)</small> -->
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-6">Upload your
                                                                    Portfolio
                                                                    Documents</div>
                                                                <div class="col-md-6">
                                                                    <div class="input-group">
                                                                        <input type="file" formControlName="UPLOAD_3"
                                                                            (change)="changeFile($event,3)"
                                                                            class="form-control"
                                                                            placeholder="Recipient's username"
                                                                            aria-label="Recipient's username"
                                                                            aria-describedby="basic-addon2"
                                                                            #fileInputRef>
                                                                        <span class="input-group-text"
                                                                            style="cursor: pointer;" id="basic-addon2"
                                                                            (click)="removeFile('2')">Remove</span>
                                                                    </div>
                                                                    <div class="text-blue"><small>(If
                                                                            you
                                                                            applying for RPL
                                                                            your
                                                                            portfolio documents should
                                                                            be
                                                                            scanned and
                                                                            uploaded.)</small>
                                                                    </div>
                                                                    <p *ngIf="upload_3 !=''">
                                                                        {{upload_3}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-3">
                                                                <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                    (click)="previousTab()">Previous</a>
                                                                <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="nextTab()">Next</a>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer bg-white mt-3">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="tab-pane fade" id="Working-Exp-tab" role="tabpanel"
                                    aria-labelledby="Working-Exp">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body ">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Working/Job Experience
                                                                </h6>
                                                            </div>
                                                            <div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">
                                                                        Company/Employer
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            [(ngModel)]="WORKEXP_DETAIL_EMPLOYER"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                        <!-- <span class="text-blue"><small>(the
                                                                            field of
                                                                            study/programme
                                                                            you are applying for in
                                                                            this
                                                                            application)</small></span> -->
                                                                    </div>
                                                                </div>



                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Start
                                                                        Date
                                                                    </div>


                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Year</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedYearStartWrk"
                                                                                (change)="generateDropdowns();validateDateRange('wrk');clearMonthDayWrk('start')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let year of years"
                                                                                    [value]="year">{{
                                                                                    year }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-2">
                                                                        <div class="form-group">
                                                                            <label>Month</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedMonthStartWrk"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let month of months"
                                                                                    [value]="month">{{
                                                                                    month }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Day</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedDayStartWrk"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let day of wrkStartDays"
                                                                                    [value]="day">{{ day
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="row mb-">
                                                                    <div class="col-md-4">End date
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Year</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedYearEndWrk"
                                                                                (change)="generateDropdowns();validateDateRange('wrk');clearMonthDayWrk('start')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let year of years"
                                                                                    [value]="year">{{
                                                                                    year }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-2">
                                                                        <div class="form-group">
                                                                            <label>Month</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedMonthEndWrk"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let month of months"
                                                                                    [value]="month">{{
                                                                                    month }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Day</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedDayEndWrk"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let day of wrkEndDays"
                                                                                    [value]="day">{{ day
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <!-- <div class="row mb-2">
                                                                <div class="col-md-4">Start Date
                                                                </div>
                                                                <div class="col-md-6"> <input
                                                                        type="date"
                                                                        [(ngModel)]="WORKEXP_DETAIL_FROM_YEAR"
                                                                        (keydown)="disableDate()"
                                                                        (change)="validateStartDate('w')"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">End Date</div>
                                                                <div class="col-md-6"> <input
                                                                        type="date"
                                                                        (change)="validateEndDate('w')"
                                                                        [(ngModel)]="WORKEXP_DETAIL_TO_YEAR"
                                                                        (keydown)="disableDate()"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div> -->
                                                                <div class="row mb-2 pt-2">
                                                                    <div class="col-md-4">Designation
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            [(ngModel)]="WORKEXP_DETAIL_DESIGNATION"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                    <!-- <div
                                                                    class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                                                    <button
                                                                        class="btn btn-danger btn-sm"
                                                                        (click)="removeApplicantDetails(i)">Remove</button>
                                                                </div> -->
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-12 text-center">
                                                                        <button type="button"
                                                                            class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                            (click)="addApplicantDetails()">Add and
                                                                            Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div *ngIf="ApplicantData.length">
                                                                <table
                                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small table-responsive"
                                                                    style="width:100%">
                                                                    <thead class="top"
                                                                        style="background-color: #624FD7; color:#ffffff;">
                                                                        <tr>
                                                                            <th>Company/Employer</th>

                                                                            <th>Start Date</th>
                                                                            <th>End Date</th>
                                                                            <th>Designation</th>

                                                                            <th>Action</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let row of ApplicantData;let i = index">
                                                                            <td>{{row?.WORKEXP_DETAIL_EMPLOYER}}
                                                                            </td>
                                                                            <td>{{row?.WORKEXP_DETAIL_FROM_YEAR}}
                                                                            </td>
                                                                            <td>{{row?.WORKEXP_DETAIL_TO_YEAR}}
                                                                            </td>
                                                                            <td>{{row?.WORKEXP_DETAIL_DESIGNATION}}
                                                                            </td>
                                                                            <td style="display: flex;">
                                                                                <button class="btn btn-info btn-sm"
                                                                                    (click)="editdata(row,'wrkExp')">Edit</button>
                                                                                &nbsp;&nbsp;
                                                                                <button class="btn btn-danger btn-sm "
                                                                                    (click)="removeApplicantDetails(i)">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="text-center" *ngIf="!ApplicantData.length">
                                                                No Records to display
                                                            </div>

                                                            <!-- <div class="row mb-2">
                                                        <div class="col-md-12 text-center"><button
                                                                type="button"
                                                                class="btn btn-sm admin-btn btn-sm px-4 rounded-0">Add</button>
                                                        </div>
                                                    </div> -->
                                                        </div>
                                                        <div class="col-12 mt-3">
                                                            <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                (click)="previousTab()">Previous</a>
                                                            <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                (click)="nextTab()">Next</a>
                                                            <!-- <button
                                                                class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                (click)="nextTab()"
                                                                [disabled]="!ApplicantData.length">Next</button> -->
                                                        </div>
                                                    </div>
                                                    <div class="card-footer bg-white mt-3">
                                                        <div style="width: 35%" class="mb-2"><img
                                                                src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                class="img-fluid" alt=""></div>
                                                        <p class="small">OUK is a public university that
                                                            was
                                                            established and
                                                            Chartered in 2023. We
                                                            aim
                                                            to provide
                                                            inclusive and flexible high quality higher
                                                            education
                                                            opportunities suited for the global
                                                            citizens in the
                                                            21st century. At OUK admissions, we recruit
                                                            and
                                                            enroll diverse
                                                            class of students.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Recommendations-tab" role="tabpanel"
                                    aria-labelledby="Recommendations">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body ">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Recommendations</h6>
                                                            </div>
                                                            <div>


                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Full Name
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            [(ngModel)]="NAME"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                        <!-- <span class="text-blue"><small>(the
                                                                            field of
                                                                            study/programme
                                                                            you are applying for in
                                                                            this
                                                                            application)</small></span> -->
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Organization
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [(ngModel)]="ORGANIZATION"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Mobile Number
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            (keypress)="onKeyPress($event)"
                                                                            maxlength="12" [(ngModel)]="MOBILE_NUMBER"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                        <span class="text-blue"><small>Include
                                                                                your country code in the
                                                                                designated
                                                                                field.</small></span>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">City/Town
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [(ngModel)]="CITY_TOWN"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Nationality
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <select class="form-select form-control-sm"
                                                                            [(ngModel)]="NATIONALITY"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                            <option value="">Select
                                                                            </option>
                                                                            <option *ngFor="let item of nationalityList"
                                                                                [value]="item.NAN_ID">
                                                                                {{item.NAN_CODE}}
                                                                            </option>
                                                                        </select>
                                                                        <!-- <input
                                                                        type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                        [(ngModel)]="NATIONALITY"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        class="form-control form-control-sm"> -->
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Email Address
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            (change)="validateEmail($event,'sponserEmail')"
                                                                            [(ngModel)]="EMAIL_ADDRESS"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>

                                                                </div>
                                                                <!-- <div
                                                                class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                                                <button
                                                                    class="btn btn-danger btn-sm "
                                                                    (click)="removeRefreeData(i)">Remove</button>
                                                            </div> -->
                                                                <div class="row mb-2">
                                                                    <div class="col-md-12 text-center">
                                                                        <button type="button"
                                                                            class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                            (click)="addRefreeData()">Add and
                                                                            Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div *ngIf="RefreeData.length" class="table-responsive">
                                                                <table
                                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small "
                                                                    style="width:100%">
                                                                    <thead class="top"
                                                                        style="background-color: #624FD7; color:#ffffff;">
                                                                        <tr>
                                                                            <th>Full Name</th>
                                                                            <th>Organization</th>
                                                                            <th>Mobile Number</th>
                                                                            <!-- <th>City/Town</th> -->
                                                                            <!-- <th>Nationality</th> -->
                                                                            <th>Email Address</th>
                                                                            <th>Action</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let row of RefreeData;let i = index">
                                                                            <td>{{row?.NAME}}</td>
                                                                            <td>{{row?.ORGANIZATION}}
                                                                            </td>
                                                                            <td>{{row?.MOBILE_NUMBER}}
                                                                            </td>
                                                                            <!-- <td>{{row.CITY_TOWN}}</td> -->
                                                                            <!-- <td>{{row.NATIONALITY}}</td> -->
                                                                            <td>{{row?.EMAIL_ADDRESS}}
                                                                            </td>
                                                                            <!-- <td>{{row.MOBILE_NUMBER}}</td> -->
                                                                            <td style="display: flex;">
                                                                                <button class="btn btn-info btn-sm"
                                                                                    (click)="editdata(row,'refre')">Edit</button>
                                                                                &nbsp;&nbsp;
                                                                                <button class="btn btn-danger btn-sm "
                                                                                    (click)="removeRefreeData(i)">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="text-center" *ngIf="!RefreeData.length">
                                                                No Records to display
                                                            </div>
                                                            <div class="col-12 mt-3">
                                                                <!-- <button type="button"
                                                                class="btn btn-sm admin-btn btn-sm px-4 rounded-0 text-center"
                                                                (click)="addRefreeData()">Add</button> -->
                                                                <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                    (click)="previousTab()">Previous</a>
                                                                <!-- <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="nextTab()">Next</a> -->
                                                                <button
                                                                    class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="nextTab()"
                                                                    [disabled]="!RefreeData.length">Next</button>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer bg-white mt-3">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Next-of-Kin-tab" role="tabpanel"
                                aria-labelledby="Next-of-Kin">
                                <div class="card border-0 card-shadow h-100">
                                    <div class="card-body assesment pt-0 style-1 ">
                                        <div class="row">
                                            <div class="col-md-12 m-auto">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body">
                                                        <div class="card_title mb-3 px-2 py-1 text-white">
                                                            <h6 class="mb-0">Next of Kin/
                                                                Emergency
                                                                Contact</h6>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Full Name <span
                                                                    class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-6"> <input type="text"
                                                                    formControlName="NAME_OF_EMERGENCY"
                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                    class="form-control form-control-sm">
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Relationship <span
                                                                    class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <select class="form-select form-select-sm"
                                                                    formControlName="RELATIONSHIPNAME_OF_EMERGENCY">
                                                                    <option value="0">Select
                                                                        Relationship</option>
                                                                    <option value="1">Father
                                                                    </option>
                                                                    <option value="2">Mother
                                                                    </option>
                                                                    <option value="3">Guardian
                                                                    </option>
                                                                    <option value="4">Spouse
                                                                    </option>
                                                                </select>
                                                                <!-- <input
                                                                type="text"
                                                                formControlName="RELATIONSHIPNAME_OF_EMERGENCY"
                                                                onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                class="form-control form-control-sm"> -->
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Country <span class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-6"> <select
                                                                    (change)="changeCountry($any($event.target)?.value,'nextofKin')"
                                                                    class="form-select form-select-sm"
                                                                    formControlName="COUNTRYID_OF_EMERGENCY">
                                                                    <option value="0">Select
                                                                    </option>
                                                                    <option *ngFor='let item of country'
                                                                        [value]='item.COUNTRY_ID'>
                                                                        {{item.COUNTRY_NAME}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2" *ngIf="nextofKinCountyDrpdown">
                                                            <div class="col-md-4">County
                                                                <span class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-6"> <select
                                                                    class="form-select form-select-sm"
                                                                    formControlName="COUNTIES_ID_OF_EMERGENCY">
                                                                    <option value="">Select
                                                                    </option>
                                                                    <option *ngFor='let item of County'
                                                                        [value]='item.COUNTIES_CODE'>
                                                                        {{item.COUNTIES_NAME}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Telephone <span
                                                                    class="text-danger"
                                                                    style="font-size:20px;">*</span>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <select class="form-select form-select-sm"
                                                                    formControlName="EMERGENCY_MOBILENO_CODE_ID"
                                                                    [(ngModel)]="emergencyCountryCode">
                                                                    <option *ngFor="let item of country"
                                                                        [value]="item.COUNTRY_ID">
                                                                        {{item.COUNTRY_CONNECTING_CODE}}
                                                                    </option>
                                                                </select>
                                                                <!-- <input type="text" disabled class="form-control form-control-sm" [(ngModel)]="emergencyCountryCode" [ngModelOptions]="{standalone:true}"> -->
                                                            </div>&nbsp;
                                                            <div class="col-md-4"> <input type="text"
                                                                    placeholder="Ex : 712345678, do not start with 0"
                                                                    (keypress)="onKeyPress($event)"
                                                                    (blur)="mobileValidation($event,3)"
                                                                    formControlName="MOBILE_NO_EMERGENCY"
                                                                    maxlength="12"
                                                                    class="form-control form-control-sm">
                                                                <!-- <span class="text-blue"><small>Include
                                                                    your country code in the
                                                                    designated
                                                                    field.</small></span> -->
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Email Address
                                                            </div>
                                                            <div class="col-md-6"><input type="text"
                                                                    (change)="validateEmail($event,'Email')"
                                                                    class="form-control form-control-sm"
                                                                    [(ngModel)]="Emailid"
                                                                    formControlName="EMAILID_OF_EMERGENCY">
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Postal Address
                                                            </div>
                                                            <div class="col-md-6"> <input type="text"
                                                                    placeholder="P.O.Box"
                                                                    formControlName="ADDRESS_OF_EMERGENCY"
                                                                    class="form-control form-control-sm">
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">Postal Code</div>
                                                            <div class="col-md-6"><input type="text"
                                                                    class="form-control form-control-sm"
                                                                    maxlength="10"
                                                                    formControlName="POSTALCODE_OF_EMERGENCY">
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-md-4">City/Town</div>
                                                            <div class="col-md-6"> <input type="text"
                                                                    formControlName="TOWNCITY_OF_EMERGENCY"
                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                    class="form-control form-control-sm">
                                                            </div>
                                                        </div>



                                                        <div class="col-12 mt-3">
                                                            <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                (click)="previousTab()">Previous</a>
                                                            <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                (click)="nextTab()">Next</a>
                                                        </div>
                                                    </div>
                                                    <div class="card-footer bg-white mt-3">
                                                        <div style="width: 35%" class="mb-2"><img
                                                                src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                class="img-fluid" alt=""></div>
                                                        <p class="small">OUK is a public university
                                                            that
                                                            was established
                                                            and Chartered in 2023. We
                                                            aim
                                                            to provide
                                                            inclusive and flexible high quality
                                                            higher
                                                            education
                                                            opportunities suited for the global
                                                            citizens in the
                                                            21st century. At OUK admissions, we
                                                            recruit
                                                            and enroll
                                                            diverse class of students.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="tab-pane fade" id="Review-tab" role="tabpanel" aria-labelledby="Review">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body ">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Review and submit</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-12">Remember that
                                                                    once
                                                                    you submit your
                                                                    application you cannot make any
                                                                    additional changes or
                                                                    view the application.</div>
                                                            </div>
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0 text-center">Review your
                                                                    filled application
                                                                    form here</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-12 p-4 h4 text-blue text-center"
                                                                    style="background-color: #CBCBCB;">
                                                                    <!-- *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)" -->
                                                                    <button
                                                                        class="btn btn-sm admin-btn-blue btn-sm px-4 rounded-0"
                                                                        (click)="update(signUpForm,4,0);">Save
                                                                        & Preview</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="card-footer bg-white mt-3">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" id="updateClose"
                    data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-info btn-sm" [disabled]="userDetails?.result?.is_submit==3"
                    (click)="update(signUpForm,4,1)"
                    *ngIf="userDetails?.result?.is_submit!=5 && aprvId != 4">Update</button>
                <button type="button" class="btn btn-info btn-sm" (click)="update(signUpForm,8)"
                    *ngIf="userDetails?.result?.is_submit==5 && aprvId == 4">Enroll</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" tabindex="-1" id="commentsModel">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h6>{{LevelTwoAdminComments}}</h6>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
