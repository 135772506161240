import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
const url = environment.fileUrl

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  roleId: any = localStorage.getItem('RoleId') || 3;
  tenantCode:any=localStorage.getItem('TenantCode');
  menus: Array<any> = []
  clientLogo: any;
  isLogo: boolean = false;
  url: any;
  selectedMenuItem: any = 'Dashboard'; // Initialize with 'Dashboard' or the default active item
  lastSelectedMenuItem: any = null; // Initialize as null
    constructor(private renderer: Renderer2, private route: ActivatedRoute, private router: Router, private service: CommonService, private sanitizer: DomSanitizer) {
    this.loadScript();
    this.getMenu();
    this.roleId = atob(this.roleId);
    if (localStorage.getItem('Logo') == "") {
      this.isLogo = true;
    } else {
      this.isLogo = false;
    }
  }

  ngOnInit(): void {
    this.url = window.location.pathname;
    this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${localStorage.getItem('Logo')}`);
  }
  getClass(name) {
    return "mdi " + name + " menu-icon"
  }
  loadScript() {
    const script = this.renderer.createElement('script');
    script.src = 'assets/plugins/metismenu/js/metisMenu.min.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
  }
  navigate(path, menu) {
    if (menu.Name === 'Dashboard') {
      // Only set the last selected item if it's not the Dashboard
      if (this.selectedMenuItem !== 'Dashboard') {
        this.lastSelectedMenuItem = this.selectedMenuItem;
      }
    }
    this.selectedMenuItem = menu.Name;
    // Handle navigation logic here
  
    if (!menu.childs?.length && path) {
      let url = this.url.replaceAll('/HOME/', '');

      let findPath = JSON.stringify(this.menus).includes(path || url);
      if (findPath) {
        this.router.navigate(['HOME/' + path]);
        this.closeLeftMenuforMobile();
        return
      } else {
        alert(12)
      }


      ;
    }

  }
  navigateToOuk() {
    window.open('https://ouk.ac.ke/')
  }
  getMenu() {
    let payLoad = {
      Roleid_base64string: localStorage.getItem('RoleId'),
      USER_ID: parseInt(localStorage.getItem('UserId')),
      TENANT_CODE: parseInt(localStorage.getItem('TenantCode'))
    }
    this.service.postCall('Account/LoadMenusByRoleId', payLoad).subscribe((res) => {
      this.menus = res;
      // if()
      // let url = this.url.replaceAll('/HOME/', '');
      // let findPath = JSON.stringify(this.menus).includes(url);
      // if(findPath){
      //   return
      // } else {
      //   this.router.navigate(['/notFound']);

      
      // }

    }, err => { })
  }
  getId(id: any) {
    return id.replaceAll(/\s/g, '')
  }
  smartHr() {

    window.open('https://smhrqainternal.dhanushinfotech.com/Admin');
  }

  closeLeftMenuforMobile() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      const idClickEvent = document.getElementById('sidebar');
      if (idClickEvent) {
        idClickEvent.classList.value = 'wrapper';
      }
    }
  }
  userGuide() {
    const URL = `${url}home/KNA/OUK-StudentRegistration-UserGuide.pdf`
    window.open(URL)
  }
}
