<div>
    <div class="mx-4  mt-4 float-end">
        <ng-autocomplete [data]="CourseList" [searchKeyword]="keyword" placeholder="Search Programme"
            (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>
        <i class='bx bx-search' style="position: absolute;top: 25px;right: 30px; color: #C9C9C9; font-weight: 600;"></i>

    </div>
    <div class="clearfix"></div>
    <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.COURSE_NAME"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
    </ng-template>
</div>