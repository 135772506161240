<section [ngClass]="{'pt-1':!isShowAll}" [ngStyle]="{'padding-top':isShowAll?'7rem':null}">
    <div class="container post pt-1">
        <div class="row DCB-card" style="padding-top: 1.25rem!important;">
            <div class="col-xl-4 col-md-6 mb-5" *ngFor="let course of courseList;">

                <div class="card shadow-sm h-100">
                    <div style="position:relative;">
                        <img [src]="course.IMAGE_URL" class="card-img-top" alt="..." width="354" height="185">
                        <span href="#" class="badge rounded-pill bg-light text-dark user-pill px-3 py-2">
                           
                        </span>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title mt-2">{{course.COURSE_NAME}}</h5>
                        <p class="card-text">{{course?.COURSE_DESCRIPTION?.substr(0,150)}}
                            <a *ngIf="course.COURSE_DESCRIPTION?.length>75" (click)="gotoCourseDetail(course)" href="javascript:return(0);">View More</a>
                        </p>
                        <div class="text-center">
                            <!-- <div><span class="price me-2"> {{course.COURSESHD_AMOUNT |currency:'INR'}}</span>
                            </div> -->
                            <div><span><button class="btn btn-sm btn-seeall-1" (click)="gotoCourseDetail(course)"   >View Programme</button></span> </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="card profile-card-3 h-100">
                    <div class="background-block">
                        <img [src]="course.IMAGE_URL" alt="profile-sample1" class="background" />
                    </div>

                    <div class="card-content p-4">
                        <div class="text-end mb-3" style="position:absolute; right:10px; top:-30px;">
                            <h2 class="">{{course.COURSE_NAME}}</h2>
                        
                        </div>

                        <div class="text-start mt-4">
                          

                            <p class="text-white">
                                {{course.COURSE_DESCRIPTION.substr(0,150)}}
                                <a *ngIf="course.COURSE_DESCRIPTION.length>75" (click)="gotoCourseDetail(course)"
                                    href="javascript:return(0);">View More</a>
                            </p>
                            <div class="d-flex justify-content-between">
                                <div class="d-none">
                                    <span class="text-white me-2 fw-bold">{{'1' |currency: ' KSH '}}</span>

                                </div>
                                <div><span><button class="btn btn-sm btn-outline-light"
                                            (click)="gotoCourseDetail(course)">View Program</button></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->
            </div>

        </div>
        <!-- <div class="text-center mb-3">
            <button type="button" class="btn btn-blue btn-sm">Small button</button>
        </div> -->
    </div>
</section>
